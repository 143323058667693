@use '../abstracts' as *;

/* ##############################################################################

    PAGE

############################################################################## */

.body_light {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: screen;
  transform: translate3d(0,0,0);
  pointer-events: none;
  // @include mq(sp) {
  //   opacity: 0;
  // }
}

/* related_page
********************************************** */
.section-related_page {
  position: relative;
  z-index: 1;
  padding-bottom: rem(120);
  background-size: cover;
  background-position: bottom center;
  background-image: url(../images/common/contents-bg.jpg);
  background-attachment: fixed;
  @include mq(sp) {
    padding-bottom: sprem(40);
    background-image: url(../images/common/contents-bg-sp.jpg);
  }
}
.section-related_page .inner {
  border-top: 1px solid;
}
.related_page--ttl {
  color: #fff;
  font-size: rem(120);
  font-weight: normal;
  @include mq(sp) {
    font-size: rem(32);
  }
}
.related_page--list {
  position: relative;
  z-index: 1;
  margin: 0 rem(40);
  @include mq(sp) {
    margin: 0;
  }
}
.related_page--list li {
  border-bottom: 1px solid;
}
.related_page--list a {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: rem(64) 0;
  line-height: var(--line-height-hdr);
  @include mq(sp) {
    display: block;
    padding: sprem(24) sprem(32) sprem(24) sprem(24);
  }
  &:hover {
    padding-left: rem(56);
    color: var(--clr-primary-default);
    @include mq(sp) {
      padding-left: sprem(32);
    }
  }
}
.related_page--list .arrow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  @include rect(100);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  transition: all .4s ease-out;
  @include mq(sp) {
    @include sprect(48);
  }
  svg {
    @include rect(18);
    @include mq(sp) {
      @include sprect(13);
    }
  }
}
.related_page--list a:hover .arrow {
  border-color: var(--clr-text-secondary);
  svg {
    fill: var(--clr-primary-default);
  }
}
.related_page--list .jp {
  margin-bottom: rem(8);
  @include mq(sp) {
    margin-top: sprem(8);
    margin-bottom: 0;
    font-size: sprem(13);
  }
}
.related_page--list .font-en {
  margin-right: rem(24);
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1;
  @include mq(sp) {
    display: block;
    font-size: 2rem;
  }
}
.related_page--bg {
  position: absolute;
  top: 0;
  right: rem(200);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: rem(330);
  height: rem(187);
  border-radius: rem(4);
  transition: all .4s ease-out;
  opacity: 0;
  @include mq(sp) {
    display: none !important;
  }
}
.related_page--list a:hover .related_page--bg {
  opacity: 1;
}

/* page-online
********************************************** */
.page-online #section-01 .slide-fit {
  @include mq(sp) {
    width: 93%;
    margin-left: auto;
  }
}

/* page-thanks
********************************************** */
.page-thanks {
  .lps_anchor {
    display: none;
  }
  .lps_wrap {
    width: 100%;
  }
  .lps_sec {
    padding-left: 0;
    margin-left: 0;
    width: 100%;
  }
  .lps_parts > .inner {
    margin-left: auto;
  }
}