@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: rem(32);
  }

  /* --- 目次 --- */
  .ez-toc-debug-messages {
    display: none;
  }
  div#ez-toc-container {
    padding: rem(24);
    background-color: #fafafa;
    p.ez-toc-title {
      color: var(--clr-text-primary);
      font-weight: bold;
      font-size: calc-fz(20);
    }
    a,
    a:hover,
    a:visited {
      color: var(--color-primary-default) !important;
    }
  }
  .ez-toc-title-container {
    margin-bottom: rem(10);
  }
  #ez-toc-container.counter-hierarchy {
    ul {
      font-size: calc-fz(17);
    }
    ul li {
      margin-top: rem(8);
    }
    ul ul {
      margin-left: rem(16);
    }
    ul ul a {
      position: relative;
      display: inline-block;
      padding-left: rem(16);
      &::before {
        content: "";
        position: absolute;
        top: rem(10);
        left: 0;
        width: rem(8);
        height: 0;
        border-bottom: 1px solid;
      }
    }
  }

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: rem(5);
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: rem(4) rem(6);
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: rem(4);
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* share
**************************************** */
  .share--list a {
    @include center-flex;
    height: rem(40);
    padding: .5em;
    line-height: 1;
    font-size: calc-fz(12);
    font-weight: bold;
    border: 1px solid #ddd;
  }
  .share--list li {
    &.x a { color: #000; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .share--list img {
    margin-right: rem(10);
  }
  .share--list a:hover {
    opacity: .5;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: calc-clamp(4, $wid-var);
    white-space: nowrap;
  }
  .wp-pagenavi > * {
    @include center-flex;
  }
  .wp-pagenavi .pages {
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi .current {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
    border-color: var(--clr-primary-default);
  }
  .wp-pagenavi a {
    position: relative;
    padding: 0 1em;
    height: calc-clamp(40, $wid-var);
    color: var(--clr-primary-default);
    border: 1px solid var(--clr-primary-default);
    background-color: var(--clr-wht);
    @include mq(sp) {
      height: sprem(36);
    }
    &:hover {
      color: var(--clr-wht);
      background-color: var(--clr-primary-default);
    }
  }
  .archive .wp-pagenavi > *:not(.pages) {
    width: calc-clamp(40, $wid-var);
    aspect-ratio: 1;
    @include mq(sp) {
      width: sprem(36);
    }
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    aspect-ratio: 1;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    width: .5em;
    aspect-ratio: 1;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: rem(-6);
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: rem(-6);
  }
