@use "../abstracts" as *;

/* page_ttl
********************************************** */
.page_ttl {
  position: relative;
  padding-top: rem(200);
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  background-image: url(../images/common/contents-bg.jpg);
  @include mq(sp) {
    padding-top: sprem(80);
    background-position: bottom center;
    background-image: url(../images/common/contents-bg-sp.jpg);
  }
  & > .inner {
    position: relative;
    z-index: 1;
  }
  .ring_deco {
    position: absolute;
    &.ring_deco1 {
      left: 10%;
      bottom: rem(660);
      width: rem(242);
      height: rem(248);
      transform: rotate(88.64deg);
      opacity: 0;
      @include mq(sp) {
        top: 14%;
        left: auto;
        right: 10%;
        bottom: auto;
        width: sprem(57);
        height: sprem(57);
      }
    }
    &.ring_deco2 {
      top: 30%;
      left: 30%;
      width: rem(154);
      height: rem(160);
      filter: blur(6px);
      transform: rotate(33.09deg);
      opacity: 0;
      @include mq(sp) {
        top: 23%;
        left: 44%;
        width: sprem(80);
        height: sprem(80);
      }
    }
    &.ring_deco3 {
      top: 10%;
      right: 22%;
      width: rem(104);
      height: rem(110);
      transform: rotate(17.83deg);
      opacity: 0;
      @include mq(sp) {
        top: 41%;
        left: 2%;
        right: auto;
        width: sprem(129);
        height: sprem(129);
      }
    }
    &.ring_deco4 {
      z-index: 3;
      right: 0;
      bottom: rem(-320);
      overflow: hidden;
      @include mq(sp) {
        width: sprem(160);
        bottom: sprem(-80);
        overflow: hidden;
      }
      .fuwafuwa-x,
      .fuwafuwa-y {
        width: rem(410);
        height: rem(486);
        @include mq(sp) {
          width: sprem(218);
          height: sprem(218);
        }
      }
      img {
        width: auto;
        max-width: none;
        height: 80%;
        margin-top: rem(10);
        margin-left: rem(10);
        filter: blur(6px);
        transform: rotate(88.64deg);
        @include mq(sp) {
          height: 76%;
          margin-top: sprem(26);
          margin-left: sprem(24);
        }
      }
    }
  }
}
.page_ttl--bg {
  width: 100%;
  height: rem(709);
  background-position: center;
  background-size: cover;
  @include mq(sp) {
    height: sprem(317);
  }
}
.page_ttl--bg:has(+ .pc-none) {
  @include mq(sp) {
    display: none;
  }
}
.page_ttl-jp {
  font-size: rem(24);
  letter-spacing: 0.26em;
  opacity: 0;
  @include mq(sp) {
    font-size: sprem(12);
    letter-spacing: .1em;
  }
}
.page_ttl_wrap-en {
  opacity: 0;
}
.page_ttl-en {
  display: inline-block;
  text-transform: capitalize;
  font-size: calc-fz(64);
  background-image: linear-gradient(
    70deg,
    rgb(69, 78, 87, 1) 45%, 
    #fff 50%,
    rgb(69, 78, 87, 1) 55% 
  );
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  @include mq(sp) {
    margin-bottom: sprem(14);
    font-size: sprem(32);
    line-height: 1.05;
  }
}

.slug_loop_text {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  overflow: hidden;
  font-size: rem(208);
  color: #fff;
  letter-spacing: -.02em;
  line-height: 1;
  text-transform: capitalize;
  opacity: .3;
  mix-blend-mode: overlay;
  @include mq(sp) {
    font-size: sprem(90);
  }
}
@keyframes  loop_animation_right_double {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}
.loop {
  animation: loop_animation_right_double 60s linear infinite;
  white-space: nowrap;
}

.page_ttl.page_ttl-has_bg {
  padding-top: rem(280);
  background-image: url(../images/common/fv-bg.jpg);
  background-size: cover;
  @include mq(sp) {
    padding-top: sprem(118);
    background-size: cover;
    background-image: url(../images/common/fv-bg-sp.jpg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: rem(761);
    height: rem(860);
    background-size: 100% auto;
    background-position: center;
    background-image: url(../images/common/lights-04.png);
    mix-blend-mode: color-dodge;
    opacity: 50%;
    @include mq(sp) {
      display: none;
    }
  }
  .breadcrumbs {
    margin-top: rem(260);
    @include mq(sp) {
      margin-top: sprem(32);
    }
  }
  .slug_loop_text {
    bottom: rem(709);
    @include mq(sp) {
      bottom: sprem(317);
    }
  }
}

/* ttl
********************************************** */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04,
.ttl-05,
.ttl-06 {
  position: relative;
  letter-spacing: 0.1em;
  font-weight: normal;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
  span {
    line-height: inherit;
    letter-spacing: inherit;
  }
  sup {
    display: inline-block;
    line-height: 0;
    font-size: 182%;
    letter-spacing: 0;
    vertical-align: middle;
  }
}
.ttl-01 {
  font-size: rem(20);
  letter-spacing: .1em;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: rem(72);
    @include mq(sp) {
      margin-bottom: sprem(32);
    }
  }
  @include mq(sp) {
    font-size: sprem(12);
  }
  &.font-en {
    font-size: rem(68);
    @include mq(sp) {
      font-size: sprem(40);
    }
  }
  .subtitle {
    margin-bottom: rem(16) !important;
    font-family: "CabinetGrotesk-Medium", "Shuei KakuGo Gin M", sans-serif;
    font-weight: normal;
    letter-spacing: -.02em;
    @include mq(sp) {
      line-height: 1.125;
    }
  }
  &.clr-wht .subtitle {
    color: var(--clr-wht);
    &::after {
      background-color: var(--clr-wht);
    }
  }
}
.lps_wrap .ttl-01 {
  @include mq(sp) {
    width: 86%;
    margin-left: auto;
    margin-right: auto;
  }
}
.ttl-02 {
  font-size: rem(44);
  font-weight: normal;
  line-height: 1.63;
  letter-spacing: .1em;
  @include mq(sp) {
    font-size: sprem(24);
    line-height: 1.5;
  }
  &.inner {
    margin-left: 0;
    @include mq(sp) {
      margin-left: auto;
    }
  }
  .font-en {
    display: block;
    margin-top: rem(16);
    font-size: rem(20);
    color: var(--clr-text-secondary);
    letter-spacing: .05em;
    @include mq(sp) {
      margin-top: sprem(8);
      font-size: sprem(12);
    }
  }
}
.ttl-03 {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(18);
    letter-spacing: .125em;
    line-height: 1.6;
  }
  .font-en {
    display: flex;
    align-items: center;
    margin-bottom: rem(16);
    color: var(--clr-text-secondary);
    font-size: rem(18);
    font-weight: normal;
    letter-spacing: 0.05em;
    &::before {
      content: "";
      display: inline-block;
      width: rem(10);
      height: rem(2);
      margin-right: rem(10);
      background-color: var(--clr-text-secondary);
    }
  }
}
.ttl-04 {
  font-size: rem(24);
  letter-spacing: .1em;
  line-height: 1.66;
  @include mq(sp) {
    font-size: sprem(20);
  }
  .font-en {
    display: flex;
    align-items: center;
    margin-bottom: rem(16);
    color: var(--clr-text-secondary);
    font-size: rem(18);
    font-weight: normal;
    letter-spacing: 0.05em;
    @include mq(sp) {
      font-size: sprem(14);
    }
    &::before {
      content: "";
      display: inline-block;
      width: rem(10);
      height: rem(2);
      margin-right: rem(10);
      background-color: var(--clr-text-secondary);
    }
  }
}
.ttl-05 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(18);
  }
  .font-en {
    display: flex;
    align-items: center;
    margin-bottom: rem(16);
    color: var(--clr-text-secondary);
    font-size: rem(18);
    font-weight: normal;
    letter-spacing: 0.05em;
    &::before {
      content: "";
      display: inline-block;
      width: rem(10);
      height: rem(2);
      margin-right: rem(10);
      background-color: var(--clr-text-secondary);
    }
  }
}
.ttl-06 {
  font-size: calc-fz(20);
  @include mq(sp) {
    font-size: sprem(16);
  }
  .font-en {
    display: flex;
    align-items: center;
    margin-bottom: rem(16);
    color: var(--clr-text-secondary);
    font-size: rem(18);
    font-weight: normal;
    letter-spacing: 0.05em;
    &::before {
      content: "";
      display: inline-block;
      width: rem(10);
      height: rem(2);
      margin-right: rem(10);
      background-color: var(--clr-text-secondary);
    }
  }
}

/* subtitle
********************************************** */
.subtitle,
.subtitle-sm {
  letter-spacing: 0.03em !important;
}
.subtitle {
  display: block;
  color: var(--clr-primary-default);
  font-size: calc-fz(50);
  @include mq(sp) {
    font-size: sprem(32);
  }
  &.font-en {
    font-size: rem(18);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(48, 16, em);
  }
}
.subtitle-sm {
  display: block;
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(12);
  }
  &.font-en {
    font-size: rem(14);
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
}
.kazari-en {
  font-size: rem(152);
  @include mq(sp) {
    font-size: sprem(56 );
  }
}