@use "../abstracts" as *;

/* anchor_link
********************************************** */
.anchor_link--list_wrap {
  position: sticky;
  top: 5%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: rem(100);
  @include mq(sp) {
    position: static;
    overflow: auto;
    height: auto;
    padding-left: sprem(30);
    padding-bottom: 0;
    border-radius: 99px 0 0 99px;
    border: 1px solid;
    align-items: center;
    background-color: #fff;
    flex-direction: row;
    overflow-x: scroll;
    font-size: sprem(13);
  }
  .anchor_link--ttl {
    margin-top: auto;
    @include mq(sp) {
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
}
.anchor_link--list {
  margin-left: rem(8);
  @include mq(sp) {
    display: flex;
    width: max-content;
    margin-left: 0;
    padding: sprem(16) sprem(24);
  }
}
.anchor_link--list li {
  @include mq(sp) {
    width: auto;
    margin-right: sprem(18);
  }
}
.anchor_link--list a {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-block: rem(12);
  padding-left: rem(24);
  line-height: var(--line-height-hdr);
  opacity: .5;
  @include mq(sp) {
    padding: sprem(8) 0 sprem(8) sprem(16);
    white-space: nowrap;
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    @include rect(8);
    background-color: var(--clr-text-primary);
    transition: var(--transit-default);
    border-radius: 50%;
    @include mq(sp) {
      top: 45%;
    }
  }
  &.current,
  &:hover {
    opacity: 1;
  }
}
