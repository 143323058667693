@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
@font-face {
  font-family: "CabinetGrotesk-Medium";
  src:
    url("../fonts/CabinetGrotesk-Medium.woff") format("woff"),
    url("../fonts/CabinetGrotesk-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "CabinetGrotesk-Regular";
  src:
    url("../fonts/CabinetGrotesk-Regular.woff") format("woff"),
    url("../fonts/CabinetGrotesk-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "CabinetGrotesk-Thin";
  src:
    url("../fonts/CabinetGrotesk-Thin.woff") format("woff"),
    url("../fonts/CabinetGrotesk-Thin.woff2") format("woff2");
}

html {
  font-size: calc-clamp($fz-pc, 1080);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-padding-top: rem($header-hgt-fx);
  @include mq(sp, min, ps) {
    @include mq(med) {
      font-size: rem($fz-sp);
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: 14px;
    scroll-padding-top: 0;
  }
  &.fixed {
    overflow-y: hidden;
  }
}
body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  color: var(--clr-text-primary);
  height: 100%;
  word-break: break-word;
  background-size: 100% auto;
  background-position: bottom center;
  background-image: url(../images/common/contents-bg.jpg);
  background-attachment: fixed;
  @include mq(sp, min, ps) {
    // フォントサイズ変更する場合　例：18pxの例：calc-clamp(18, $wid-var)
    font-size: calc-clamp($fz-pc, $wid-var);
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
    background-size: cover;
    background-position: center;
    background-image: url(../images/common/contents-bg-sp.jpg);
  }
}
* {
  letter-spacing: var(--ltr-space-default);
  @include mq(sp) {
    letter-spacing: .08em;
  }
}
ul,
ol { list-style: none; }
small { 
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a:where([href="javascript:void(0);"]):hover {
  color: var(--clr-text-primary);
  text-decoration: none;
  transition: var(--transit-default);
  outline : none;
}
a:where([href="javascript:void(0);"]),
a:where([href="javascript:void(0);"])::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:focus {
  text-decoration: none !important;
}
a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
  }
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr);
}
svg {
  transition: var(--transit-default);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}
