@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs {
  position: relative;
  z-index: 1;
  margin-top: rem(140);
  padding-bottom: rem(24);
  @include mq(sp) {
    margin-top: sprem(32);
    font-size: sprem(12);
  }
}
.breadcrumbs .inner {
  white-space: nowrap;
  @include mq(sp, min, ps) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include mq(sp) {
    overflow: auto;
  }
}
.breadcrumbs a {
  &:hover {
    color: var(--clr-primary-default);
  }
}
.breadcrumbs span span:not(:last-child)::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: rem(14);
  margin: 0 rem(10) 0 rem(16);
  background-color: #454e57;
  transform: skewX(-24deg);
}