@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.lps_container {
  position: relative;
  background-size: cover;
  background-position: bottom center;
  background-image: url(../images/common/contents-bg.jpg);
  background-attachment: fixed;
  @include mq(sp) {
    background-image: url(../images/common/contents-bg-sp.jpg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: rem(658);
    height: rem(1250);
    mix-blend-mode: screen;
    opacity: .8;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../images/common/lights-02.png);
    -webkit-mask-image: linear-gradient(90deg, #0000, #fff, #0000);
        mask-image: linear-gradient(90deg, #0000, #fff, #0000);
    -webkit-mask-size: 300% 100%;
        mask-size: 300% 100%;
    animation: clip-fade 3s infinite alternate;
    @include mq(sp) {
      width: 100%;
    }
  }
  .lps_container--light {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    mix-blend-mode: screen;
    mask-image: linear-gradient(0, #0000, #fff, #0000);
    transform: translate3d(0,0,0);
  }
}
.lps_sec {
  position: relative;
  width: 99.1vw;
  margin-left: rem(-540);
  padding-left: rem(540);
  @include mq(sp) {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
  &:nth-child(n+2) .lps_sec--wrap {
    border-top: 1px solid var(--clr-text-secondary);
  }
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}
.sec_mid_img {
  position: relative;
  width: 100vw;
  height: rem(708);
  margin-left: rem(-540);
  @include mq(sp) {
    margin-left: 0;
    height: sprem(317);
  }
}
.sec_mid_img--ring {
  position: absolute;
  &.ring1 {
    top: -15%;
    left: 20%;
    width: rem(102);
    height: rem(108);
    transform: rotate(17.83deg);
    @include mq(sp) {
      display: none;
    }
  }
  &.ring2 {
    left: 10%;
    bottom: -6%;
    width: rem(162);
    height: rem(168);
    transform: rotate(33.09deg);
    filter: blur(6px);
    @include mq(sp) {
      top: -10%;
      left: 10%;
      bottom: auto;
      width: sprem(64);
      height: sprem(70);
    }
  }
  &.ring3 {
    right: 8%;
    bottom: -22%;
    width: rem(254);
    height: rem(260);
    transform: rotate(88.64deg);
    z-index: 1;
    @include mq(sp) {
      width: sprem(94);
      height: sprem(100);
    }
  }
}
.sec_mid_img + .lps_sec {
  .lps_sec--wrap {
    border-top: none;
  }
}
.lps_anchor {
  width: rem(540);
  padding-left: rem(48);
  padding-right: rem(80);
  @include mq(sp) {
    position: fixed;
    bottom: sprem(24);
    z-index: 99;
    padding-top: 0;
    padding-left: 7%;
    padding-right: 0;
    opacity: 1;
    pointer-events: all;
    transition: all .4s ease-out;
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}
.lps_wrap {
  width: calc(100% - rem(540));
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 0;
  &:not(:last-child) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
  & > .inner {
    margin-left: 0;
    @include mq(sp) {
      margin-left: auto;
    }
  }
}
.lps_parts--column,
.lps_parts--img_text, 
.lps_parts--faq,
.lps_parts--flow,
.ttl-01,
.ttl-02 {
  z-index: 3;
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
}