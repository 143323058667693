
@use '../abstracts' as *;

  /* --- anchor --- */
  // .anchor {
  //   position: absolute;
  //   top: rem(-$header-hgt-fx);
  //   left: 0;
  //   pointer-events: none;
  //   @include mq(med){
  //     .anchor {
  //       top: rem(-$header-hgt-sp);
  //     }
  //   }
  // }