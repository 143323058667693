@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  max-width: rem(1256);
  margin-right: 0;
  @include mq(sp) {
    width: 86%;
    margin-left: auto;
    margin-right: auto;
  }
  .flow_item {
    position: relative;
    margin-inline-start: rem(90);
    @include mq(sp) {
      margin-inline-start: sprem(24);
    }
    &:not(:last-child) {
      padding-bottom: rem(50);
      @include mq(sp) {
        padding-bottom: sprem(32);
      }
    }
    &:not(:last-child) .ttl::after {
      content: '';
      position: absolute;
      top: rem(72);
      left: rem(24);
      bottom: rem(38);
      width: 1px;
      background-color: var(--clr-text-primary);
      @include mq(sp) {
        left: 0;
      }
    }
    &.clr-wht:not(:last-child) .ttl::after {
      border-color: var(--clr-wht);
    }
  }
  .ttl {
    position: static;
    &::before {
      float: inline-start;
      aspect-ratio: 1;
      margin-inline-start: calc(rem($flow-start) * -1);
      border-radius: 50%;
      @include mq(sp) {
        margin-inline-start: calc(rem($flow-start-sp) * -1);
      }
    }
  }
  .clr-wht .ttl {
    color: var(--clr-wht);
  }
  .text {
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32,768,'%');
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300,768,'%');
    }
    @include mq(sp) {
      &:not(:last-child) {
        margin-bottom: sprem(24);
      }
    }
  }
  .mce-content-body {
    margin-bottom: 0 !important;
    @include mq(sp, min, ps) {
      flex: 1;
    }
  }
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) {
  .flow_item {
    display: flex;
    @include mq(sp) {
      display: block;
      margin-inline-start: 0;
      padding-left: sprem(32);
    }
  }
  .ttl {
    font-size: rem(24);
    letter-spacing: .01em;
    font-family: var(--font-en);
    font-weight: normal;
  }
  .ttl::before {
    content: '';
    position: relative;
    @include rect(24);
    margin-left: rem(-76);
    border-radius: 50%;
    border: rem(4) solid var(--clr-primary-default);
    @include mq(sp) {
      position: absolute;
      top: 2px;
      left: sprem(-9);
      margin-left: 0;
    }
  }
  .flow_item:not(:last-child) .ttl::after {
    top: rem(40);
    left: rem(-64);
    bottom: rem(20);
    @include mq(sp) {
      left: 0;
    }
  }
  .flow_item .text {
    width: 87%;
    margin-left: auto;
    @include mq(sp) {
      width: 100%;
    }
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num {
  .flow_item {
    margin-inline-start: 0;
    padding-left: rem(84);
    @include mq(sp) {
      padding-left: sprem(24);
    }
  }
  .flow_item--num {
    position: relative;
    z-index: 1;
    margin-left: rem(-84);
    margin-bottom: rem(32);
    display: inline-flex;
    align-items: center;
    background-color: var(--clr-primary-default);
    padding: rem(4) rem(32) rem(4) rem(24);
    border-radius: 99px;
    color: #fff;
    font-size: rem(24);
    @include mq(sp) {
      margin-left: sprem(-32);
      padding: sprem(6) sprem(24) sprem(6) sprem(16);
      font-size: sprem(16);
    }
    &::before {
      content: "";
      display: inline-block;
      @include rect(8);
      border-radius: 50%;
      margin-right: rem(16);
      background-color: #b4d3de;
      @include mq(sp) {
        margin-right: sprem(10);
      }
    }
  }
  .ttl::before {
    display: none;
  }
}