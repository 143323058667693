@use '../abstracts' as *;

/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .bg-wht .img_text--child:only-child,
.lps_parts--img_text .text,
.lps_parts--img_text .img_text--txt table {
  width: 100%;
}
.lps_parts--img_text .img_text--child .img {
  height: 100%;
}
.lps_parts--img_text .img_text--img:only-child .img {
  display: table;
  @include auto-margin;
}
.lps_parts--img_text .img_text--txt {
  @include mq(sp) {
    padding-top: rem(32);
  }
}
.lps_parts--img_text .bg-wht .img_text--txt {
  padding: perc(64,1200,'%');
  @include mq(sp) {
    padding: sprem(24);
  }
}
.lps_parts--img_text .inner {
  &:not(.flex-reverse) .img_text--txt {
    @include mq(sp, min, ps) {
      padding-left: perc(64,1200,'%');
    }
  }
  &.flex-reverse .img_text--txt {
    @include mq(sp, min, ps) {
      padding-right: perc(64,1200,'%');
    }
  }
}

/* --- L --- */
.lps_parts--img_text .inner-lg {
  width: 100%;
  @include mq(sp) {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
  .img_text--img {
    position: absolute;
    top: 0;
    @include mq(sp, min, ps) {
      height: 100%;
    }
    @include mq(sp) {
      position: relative;
    }
  }
  &:not(.flex-reverse) .img_text--img {
    left: 0;
  }
  &.flex-reverse .img_text--img {
    right: 0;
  }
  .img_text--txt {
    @include mq(sp, min, ps) {
      min-height: rem(607.64);
    }
    @include mq(sp) {
      width: 86% !important;
      @include auto-margin;
    }
  }
  &:not(.flex-reverse) .img_text--txt {
    margin-left: auto;
    @include mq(sp, min, ps) {
      padding-left: rem(80);
      padding-right: rem(80);
    }
  }
  &.flex-reverse .img_text--txt {
    margin-right: auto;
    @include mq(sp, min, ps) {
      padding-left: 0;
      padding-right: rem(80);
    }
  }
  &.bg-wht .img_text--txt {
    padding-left: rem(80);
    padding-right: rem(80);
    @include mq(sp) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: sprem(40);
    }
  }
  .ttl-04 {
    font-size: rem(32);
    letter-spacing: .1em;
    line-height: 1.5;
    @include mq(sp) {
      font-size: sprem(22);
      line-height: 1.75;
    }
  }
}

// 角丸設定 ==================================

/* 白背景 */
.lps_parts--img_text .lps_parts.bg-wht {
  border-radius: rem(4);
}
.lps_parts--img_text .lps_parts--child.bg-wht {
  border-radius: rem(4);
  .img_text--img img {
    border-radius: rem(4) 0 0 rem(4);
    @include mq(sp) {
      border-radius: rem(4) rem(4) 0 0;
    }
  }
  @include mq(sp, min) {
    &.flex-reverse .img_text--img img {
      border-radius: 0 rem(4) rem(4) 0;
    }
  }
}

/* 白背景なし */
.lps_parts--img_text .lps_parts--child:not(.bg-wht) .img_text--img img {
  border-radius: rem(4);
}

/* レイアウトL
※Lも全部角丸にしたかったら削除！ */
// .lps_parts--img_text .lps_parts--child.inner-lg {
//   .img_text--img img {
//     border-radius: 0;
//   }
// }
@include mq(sp, min) {
  .lps_parts--img_text .lps_parts--child.inner-lg:where(:not(.bg-wht)) {
    .img_text--img img {
      border-radius: rem(4);
    }
    &.flex-reverse .img_text--img img {
      border-radius: rem(4);
    }
  }
}

// 角丸設定 ==================================

@include mq(sp, min, ps) {
  .lps_parts--img_text .img_text--txt {
    display: flex;
    align-items: center;
  }
  .lps_parts--img_text .inner-lg .img_text--img:only-child {
    position: relative;
  }
}
@include mq(sp) {
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .bg-wht + .bg-wht {
    margin-top: sprem(-40);
  }
}