@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: rem(14) rem(54) rem(14) rem(32);
  border-radius: 9999px;
  width: fit-content;
  min-width: min(rem(280), 100%);
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  text-decoration: none;
  @include mq(sp) {
    min-width: 100%;
    padding: sprem(12) sprem(42) sprem(12) sprem(18);
  }
}
.btn.btn-ctr a {
  @include auto-margin;
}
.btn.btn-rgt a {
  margin-left: auto;
  margin-right: 0;
}
.btn-type1 a {
  background-color: #fff;
  border: 1px solid;
  color: var(--clr-text-primary);
  &:hover {
    border-color: #c6c6c6;
    color: var(--clr-primary-default);
  }
}
.btn-type1 .icon {
  position: absolute;
  top: 0;
  right: rem(24);
  bottom: 0;
  margin-block: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(13);
  svg {
    @include rect(13);
  }
}
.btn-type1 a:hover .icon {
  svg {
    fill: var(--clr-primary-default);
  }
}
.btn-type2 a {
  background-color: transparent;
  padding-left: rem(46);
  padding-right: 0;
  color: var(--clr-text-primary);
  @include mq(sp) {
    padding-left: sprem(40);
  }
}
.btn-type2 .icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin-block: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @include rect(32);
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid var(--clr-text-primary);
  svg {
    @include rect(13);
  }
}
.btn-type2 a:hover .icon {
  border-color: #c6c6c6;
  svg {
    fill: var(--clr-primary-default);
  }
}
.btn .icon .icon-new_tab {
  @include rect(14);
}
.btn .icon .icon-pdf {
  @include rect(27,12);
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-primary-default);
  background-color: var(--clr-wht);
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
  }
  svg {
    fill: var(--clr-primary-default);
  }
  &:hover svg {
    fill: var(--clr-wht);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--clr-secondary-default);
  &:hover {
    background-color: var(--clr-secondary-dark);
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--clr-primary-default);
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--clr-primary-default);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280), 100%);
    }
  }
  a {
    min-width: 100%;
  }
}
