@use '../abstracts' as *;

/* ##############################################################################

    SEARCH

############################################################################## */

.search form {
  padding: rem(40);
  background-color: var(--clr-bg-01);
}
.search--menu_btn {
  cursor: pointer;
  padding: .25em 2em .25em 1em;
  background-color: var(--clr-wht);
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary);
  font-size: calc-fz(16);
  line-height: var(--line-height-default);
  font-weight: 500;
  transition: var(--transit-default);
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: .4em;
    right: .8em;
    margin: auto;
    width: .6em;
    height: .6em;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    transition: var(--transit-default);
  }
  &:hover {
    background-color: var(--clr-bg-02);
  }
}
.active .search--menu_btn::after {
  bottom: -.2em;
  transform: rotate(225deg);
}
.search--menu li {
  margin-inline-start: 1.25em;
  & > * {
    display: block;
  }
  input {
    float: inline-start;
    inline-size: calc-fz(18);
    aspect-ratio: 1;
    margin-block: calc((1lh - calc-fz(18)) / 2);
    margin-inline-start: -1.25em;
  }
}
.search--menu-select {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--clr-wht);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: var(--transit-default);
  li {
    margin-inline-start: 0;
    transition: var(--transit-default);
    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-bg-02);
    }
    &:hover {
      background-color: var(--clr-bg-02);
    }
  }
  input {
    display: none;
  }
  label {   
    padding: .25em 1em;
    cursor: pointer;
  }
}
.active .search--menu-select {
  visibility: visible;
  opacity: 1;
}
.search_sub_nav {
  padding-left: 1em;
}
.search--list {
  padding: rem(32);
  background-color: var(--clr-bg-01);
}