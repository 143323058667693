@use '../abstracts' as *;

/* --- pc or sp --- */
.pc-none,
.pc-none-inline,
.pc-none-table,
.pc-none-flex {
  display: none;
}
.sp-none {
  display: block;
}
.sp-none-inline {
  display: inline;
}
.sp-none-table {
  display: table;
}
.sp-none-flex {
  display: flex;
}

@include mq(sp) {
  /* --- pc or sp --- */
  .sp-none,
  .sp-none-inline,
  .sp-none-table,
  .sp-none-flex {
    display: none;
  }
  .pc-none {
    display: block;
  }
  .pc-none-inline {
    display: inline;
  }
  .pc-none-table {
    display: table;
  }
  .pc-none-flex {
    display: flex;
  }
}