@use '../abstracts' as *;

/* lps_parts--cta
********************************************** */
.lps_parts--cta {
  &:has(+ .home_footer_toplink) {
    margin-bottom: 0 !important;
  }
  .inner {
    background-color: var(--clr-bg-02);
    border-radius: rem(8);
  }
  .cta_contact {
    @include mq(sp) {
      padding-top: calc(1em + rem(10));
    }
  }
  .bg {
    position: absolute;
    bottom: 0;
    inset-inline: 0;
    height: rem(520);
    background-position: center;
    background-size: cover;
    @include mq(sp) {
      height: sprem(350);
    }
  }
}