@use "../abstracts" as *;

.home #wrapper {
  background-size: cover;
  background-position: bottom center;
  background-image: url(../images/common/contents-bg.jpg);
  background-attachment: fixed;
  @include mq(sp) {
    background-image: url(../images/common/contents-bg-sp.jpg);
  }
}

/* hero
********************************************** */
.hero {
  position: relative;
  height: rem(960);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../images/home/hero_bg.png);
  background-size: cover;
  @include mq(sp) {
    padding-top: sprem(120);
    align-items: flex-start;
    height: sprem(670);
    background-image: url(../images/common/contents-bg-sp2.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-position: bottom center;
    margin-bottom: -1px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: rem(761);
    height: rem(860);
    background-image: url(../images/common/lights-04.png);
    mix-blend-mode: screen;
    opacity: .8;
    @include mq(sp) {
      z-index: 1;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: bottom center;
    background-image: url(../images/common/contents-bg.jpg);
    background-attachment: fixed;
    @include mq(sp) {
      background-size: 100% auto;
      background-position: bottom center;
      background-repeat: no-repeat;
      background-attachment: local;
      background-image: url(../images/home/top-fv-sp-1.png);
    }
  }
}
.hero--catch {
  position: absolute;
  top: 43%;
  left: 5%;
  z-index: 2;
  @include mq(sp) {
    top: auto;
    left: 7%;
    bottom: 34vw;
  }
  span {
    display: inline-block;
    font-size: rem(40);
    letter-spacing: .26em;
    line-height: 1.875;
    @include mq(sp) {
      font-size: 6.5vw;
    }
  }
  .base {
    text-shadow:
    0 0 14px rgba(255,255,255,.5), 
    0 0 24px rgba(255,255,255,.5), 
    0 0 48px rgba(255,255,255,.5),
    0 0 124px rgba(255,255,255,.5),
    0 0 128px rgba(255,255,255,.5),
    0 0 128px rgba(255,255,255,.5),
    0 0 128px rgba(255,255,255,.5);
  }
  .light {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url(../images/home/hero_txt_blur.png);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: light-blur 10s infinite alternate;
    @include mq(sp) {
      right: 0;
    }
  }
}
@keyframes light-blur {
  0% {
    background-position: center right -100%;
  }
  50% {
    background-position: center right 200%;
  }
  100% {
    background-position: center right -100%;
  }
}
.hero--en {
  position: absolute;
  left: 0;
  right: 0;
  bottom: rem(150);
  font-size: rem(208);
  z-index: 2;
  text-align: center;
  line-height: 1;
  letter-spacing: -.02em;
  opacity: .4;
  mix-blend-mode: overlay;
  color: #fff;
  @include mq(sp) {
    bottom: 0;
    font-size: 18.2vw;
    text-align: left;
    line-height: .8;
  }
}
.hero--ring {
  position: relative;
  z-index: 2;
  width: rem(609);
  height: rem(615);
  @include mq(sp) {
    width: sprem(280);
    height: sprem(280);
  }
}
.hero--copyright {
  position: absolute;
  z-index: 2;
  top: 45%;
  right: 1%;
  transform: translateY(-50%);
  writing-mode: vertical-rl;
  font-size: rem(14);
  letter-spacing: .1em;
  @include mq(sp) {
    font-size: sprem(10);
  }
}
.scroll_down {
  position: absolute;
  z-index: 2;
  top: 76%;
  right: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .down_txt {
    margin-bottom: rem(20);
    writing-mode: vertical-rl;
    font-size: rem(14);
    letter-spacing: .1em;
    @include mq(sp) {
      font-size: sprem(10);
    }
  }
}
.scroll_down .down_bar {
  position: relative;
  width: rem(1);
  height: rem(209);
  background-color: var(--clr-primary-default);
  @include mq(sp) {
    height: sprem(97);
  }
  .dot {
    position: absolute;
    top: 0;
    left: rem(-4);
    @include rect(10);
    border-radius: 50%;
    background-color: var(--clr-primary-default);
    animation: down_dot 2s linear infinite;
  }
}
@keyframes down_dot {
  0% {
    top: 0;
    opacity: 1;
  }
  99% {
    top: 100%;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* home_greeting
********************************************** */
.home_greeting {
  position: relative;
  padding-top: rem(274);
  @include mq(sp) {
    padding-top: sprem(120);
    overflow: hidden;
  }
  & > .inner {
    position: relative;
    z-index: 3;
  }
}
.home_greeting--ring {
  position: absolute;
  &.ring1 {
    top: -16%;
    right: 40%;
    width: rem(116);
    height: rem(122);
    @include mq(sp) {
      top: -10%;
      right: 1%;
      width: sprem(93);
      height: sprem(93);
    }
    img {
      transform: rotate(-154.08deg);
      filter: blur(6px);
    }
  }
  &.ring2 {
    top: -8%;
    right: 62%;
    width: rem(52);
    height: rem(58);
    @include mq(sp) {
      top: -5%;
      right: 41%;
      width: sprem(27);
      height: sprem(27);
    }
    img {
      transform: rotate(-146.91deg);
    }
  }
  &.ring3 {
    top: 15%;
    right: 63%;
    width: rem(258);
    height: rem(264);
    @include mq(sp) {
      top: 18%;
      right: sprem(-8);
      width: sprem(66);
      height: sprem(66);
    }
    img {
      transform: rotate(88.64deg);
    }
  }
  &.ring4 {
    top: 34%;
    right: 82%;
    width: rem(158);
    height: rem(164);
    @include mq(sp) {
      top: 40%;
      right: 82%;
      width: sprem(118);
      height: sprem(118);
    }
    img {
      transform: rotate(33.09deg);
      filter: blur(6px);
    }
  }
  &.ring5 {
    top: 48%;
    left: 28%;
    width: rem(102);
    height: rem(108);
    @include mq(sp) {
      top: 46%;
      left: 40%;
      width: sprem(55);
      height: sprem(55);
    }
    img {
      transform: rotate(17.83deg);
    }
  }
  &.ring6 {
    top: 54%;
    left: 54%;
    width: rem(158);
    height: rem(164);
    @include mq(sp) {
      top: 50%;
      left: auto;
      right: -4%;
      width: sprem(127);
      height: sprem(127);
    }
    img {
      transform: rotate(33.09deg);
      filter: blur(6px);
    }
  }
  &.ring7 {
    top: 63%;
    left: 78%;
    width: rem(249);
    height: rem(255);
    @include mq(sp) {
      display: none;
    }
    img {
      transform: rotate(88.64deg);
    }
  }
  &.ring8 {
    left: 70%;
    bottom: 5%;
    width: rem(102);
    height: rem(108);
    @include mq(sp) {
      left: 79%;
      bottom: 16%;
      width: sprem(80);
      height: sprem(80);
    }
    img {
      transform: rotate(17.83deg);
    }
  }
}
.home_greeting--ttl {
  margin-bottom: rem(54);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
  .font-en {
    display: block;
    margin-bottom: rem(32);
    color: #cdcdcd;
    font-size: rem(20);
    line-height: 1;
    font-weight: normal;
    @include mq(sp) {
      margin-bottom: sprem(16);
      font-size: sprem(13);
    }
  }
  .jp,
  .light {
    display: inline-block;
    font-size: rem(36);
    line-height: 1.77;
    letter-spacing: .26em;
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
}
.home_greeting--ttl .ttl-wrap,
.home_greeting--catch .ttl-wrap,
.home_sercive--ttl .ttl-wrap,
.home_company--ttl .ttl-wrap,
.home_media--ttl .ttl-wrap,
.home_news--ttl .ttl-wrap {
  position: relative;
  display: inline-block;
  .light {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: top left;
    background-image: url(../images/home/text-blur.png);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: text-blur 10s infinite alternate;
  }
}
.home_greeting--catch .ttl-wrap .light {
  background-image: url(../images/home/hero_txt_blur.png);
}
.home_greeting--catch {
  font-size: rem(32);
  line-height: 2.8;
  @include mq(sp) {
    font-size: sprem(18);
    line-height: 2;
  }
  span {
    letter-spacing: .26em;
  }
}
.home_greeting--lft_txt {
  margin-left: auto;
  margin-right: 0;
  margin-bottom: rem(460);
  width: 50%;
  @include mq(sp) {
    width: 100%;
    margin-right: auto;
    margin-bottom: sprem(182);
  }
}
.home_greeting--rgt_txt {
  padding-left: rem(148);
  @include mq(sp) {
    padding-left: 0;
  }
}
.home_greeting .txt-lg {
  font-size: rem(20);
  line-height: 2.8;
  @include mq(sp) {
    font-size: sprem(14);
    line-height: 2;
  }
  span {
    display: inline-block;
    letter-spacing: .26em;
  }
}
.home_greeting .mgn-btm160 {
  margin-bottom: rem(160);
  @include mq(sp) {
    margin-bottom: sprem(40);
  }
}

/* home_ambitious
********************************************** */
.home_ambitious {
  position: relative;
  padding-top: rem(160);
  @include mq(sp) {
    padding-top: sprem(32);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 2;
    background-size: cover;
    @include mq(sp) {
      display: none;
    }
  }
  &::before {
    top: 20%;
    left: 0;
    width: rem(658);
    height: rem(1250);
    mix-blend-mode: screen;
    opacity: .8;
    background-image: url(../images/common/lights-02.png);
    -webkit-mask-image: linear-gradient(90deg, #0000, #fff, #0000);
        mask-image: linear-gradient(90deg, #0000, #fff, #0000);
    -webkit-mask-size: 300% 100%;
        mask-size: 300% 100%;
    animation: clip-fade 3s infinite alternate;
  }
  &::after {
    top: 4%;
    right: 0;
    width: rem(417);
    height: rem(1338);
    mix-blend-mode: screen;
    opacity: .8;
    background-image: url(../images/common/lights-03.png);
    -webkit-mask-image: linear-gradient(90deg, #0000, #fff, #0000);
        mask-image: linear-gradient(90deg, #0000, #fff, #0000);
    -webkit-mask-size: 200% 100%;
        mask-size: 200% 100%;
    animation: clip-fade 4s infinite alternate;
  }
}
.home_ambitious--ring {
  position: absolute;
  &.ring1 {
    top: 7%;
    left: 50%;
    filter: blur(6px);
    width: rem(158);
    height: rem(164);
    @include mq(sp) {
      top: 0;
      left: auto;
      right: 30%;
      width: sprem(57);
      height: sprem(57);
      filter: blur(0);
    }
    img {
      transform: rotate(33.09deg);
    }
  }
  &.ring2 {
    z-index: 5;
    top: 19%;
    left: 20%;
    width: rem(248);
    height: rem(254);
    @include mq(sp) {
      top: 6%;
      left: 9%;
      width: sprem(71);
      height: sprem(71);
      filter: blur(6px);
    }
    img {
      transform: rotate(88.64deg);
    }
  }
}
.home_ambitious--imgs {
  position: relative;
  margin-top: rem(-150);
  padding-top: rem(100);
  overflow: hidden;
  li {
    width: 110%;
    height: rem(400);
    margin-left: -5%;
    background-size: rem(4943) rem(400);
    transform: rotate(-5.73deg);
    -webkit-mask-image: linear-gradient(0deg, transparent 0%, black 100%);
    mask-image: linear-gradient(0deg, transparent 0%, black 40%);
    @include mq(sp) {
      height: sprem(91);
      background-size: sprem(922) sprem(91);

    }
  }
}
.home_ambitious--imgs li:nth-child(1) {
  background-image: url(../images/home/photo.png);
}
.home_ambitious--imgs.active li:nth-child(1) {
  animation: bgscroll 80s linear infinite;
}
.home_ambitious--imgs li:nth-child(2) {
  background-image: url(../images/home/photo02.png);
}
.home_ambitious--imgs.active li:nth-child(2) {
  animation: bgscroll-lft 80s linear infinite;
}
.home_ambitious--imgs li:nth-child(3) {
  background-image: url(../images/home/photo03.png);
}
.home_ambitious--imgs.active li:nth-child(3) {
  animation: bgscroll 80s linear infinite;
}
@include mq(sp, min) {
  @keyframes bgscroll {
    0% { background-position: 0 0; }
    100% { background-position: rem(4943) 0;}
  }
  @keyframes bgscroll-lft {
    0% { background-position: rem(4943) 0; }
    100% { background-position: 0 0; }
  }
}
@include mq(sp) {
  @keyframes bgscroll {
    0% { background-position: 0 0; }
    100% { background-position: sprem(922) 0;}
  }
  @keyframes bgscroll-lft {
    0% { background-position: sprem(922) 0; }
    100% { background-position: 0 0; }
  }
}

.home_ambitious--deco {
  width: 100%;
  overflow: hidden;
}
.home_ambitious--deco p {
  font-size: rem(195);
  line-height: 1;
  color: rgba(#fff, .3);
  mix-blend-mode: overlay;
  letter-spacing: 0;
  animation: loop_animation_right_double 40s linear infinite;
  white-space: nowrap;
  @include mq(sp) {
    font-size: sprem(77);
  }
}
.home_ambitious--deco p:nth-child(2) {
  animation: loop_animation_left_double 40s linear infinite;
}
@keyframes  loop_animation_left_double {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
.home_ambitious--txt {
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
  padding-top: rem(380);
  padding-bottom: rem(240);
  margin-top: rem(-370);
  background-image: url(../images/home/ambitious_bg.png);
  @include mq(sp) {
    margin-top: sprem(-60);
    padding-top: sprem(90);
    padding-bottom: sprem(8);
    & > .inner {
      width: 100%;
    }
  }
}
.home_ambitious--ttl {
  font-size: rem(40);
  font-weight: normal;
  text-align: center;
  color: #fff;
  @include mq(sp) {
    font-size: sprem(20);
  }
  .font-en {
    display: flex;
    justify-content: center;
    font-size: rem(195);
    @include mq(sp) {
      font-size: 11.8vw;
    }
    span {
      display: inline-block;
      letter-spacing: -.01em;
    }
  }
  .jp {
    display: inline-block;
    letter-spacing: .26em;
  }
}

/* home_sercive
********************************************** */
.home_sercive {
  position: relative;
  z-index: 9999;
  background: linear-gradient(0deg, rgba(107,183,224,0) 0%, rgba(107,183,224,1) 100%);
  padding-bottom: rem(320);
  @include mq(sp) {
    padding-bottom: 0;
    z-index: 2;
    // background-attachment: fixed;
    // background-image: url(../images/home/service-bg-sp.jpg);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url(../images/home/deep_bg.png);
    @include mq(sp) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-repeat: repeat;
      background-position: center;
      background-image: url(../images/home/service-bg-sp.jpg);
      mask-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 2%, rgba(0,0,0,1) 89%, rgba(0,0,0,0) 100%);
    }
  }
  &::after {
    top: 0;
    left: 0;
    width: rem(688);
    height: rem(1048);
    background-size: cover;
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    opacity: .8;
    background-image: url(../images/common/lights-06.png);
    @include mq(sp) {
      z-index: 2;
      width: 100%;
      height: 100%;
      opacity: .9;
      background-color: #6bb7e0;
      background-image: none;
      mix-blend-mode: normal;
      mask-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 89%, rgba(0,0,0,0) 100%);
    }
  }
}
.home_sercive--intro {
  position: relative;
  z-index: 2;
  padding-bottom: rem(72);
  border-bottom: 1px solid #fff;
  @include mq(sp) {
    z-index: 3;
  }
  p {
    flex: 1;
    margin-left: rem(280);
    font-size: rem(18);
    line-height: 2.2;
    @include mq(sp) {
      margin-top: sprem(16);
      margin-left: 0;
      font-size: sprem(14);
      line-height: 1.8;
    }
  }
}
.home_sercive--ttl {
  letter-spacing: .26em;
  .font-en {
    font-size: rem(90);
    line-height: 1;
    letter-spacing: 0;
    color: #fff;
    @include mq(sp) {
      font-size: sprem(40);
    }
  }
  .jp {
    display: block;
    @include mq(sp) {
      margin-top: sprem(8);
      font-size: sprem(12);
    }
  }
}
.home_sercive--contents {
  position: relative;
  height: 100vh;
  @include mq(sp) {
    z-index: 3;
    height: auto;
    padding-bottom: sprem(32);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
    background-image: url(../images/home/deep_bg.png);
    margin: 0 calc(50% - 50vw);
	  width: 100vw;
    height: 100vh;
    @include mq(sp) {
      display: none;
      background-position: top center;
      background-attachment: fixed;
      margin: 0 0 0 -7.8%;
      width: 116%;
      height: 100vh;
      background-image: url(../images/home/service-bg-sp.jpg);
      mask-image: linear-gradient(180deg, rgb(0, 0, 0) 96%, rgba(255, 255, 255, 0) 100%);
    }
  }
}
.home_sercive--contents .bloc {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  padding-top: rem(114);
  padding-bottom: rem(80);
  pointer-events: none;
  @include mq(sp) {
    position: relative;
    height: auto;
    padding-top: sprem(32);
    padding-bottom: sprem(32);
    pointer-events: all;
  }
}
.home_sercive--contents .deco {
  position: absolute;
  top: 50%;
  left: -9.4%;
  transform: translateY(-50%);
  display: flex;
  width: 100vw;
  overflow: hidden;
  color: #fff;
  line-height: 1;
  font-size: rem(250);
  opacity: 0;
  @include mq(sp) {
    left: -7%;
    font-size: sprem(60);
    width: 107%;
    opacity: .1;
  }
  .loop {
    animation: loop_animation_right_double 60s linear infinite;
    white-space: nowrap;
  }
}
.home_sercive--contents .bloc.current {
  pointer-events: all;
}
.home_sercive--contents .bloc.current .deco {
  opacity: .1;
}
.home_sercive--contents .txt {
  position: relative;
  z-index: 1;
  width: 41%;
  color: #fff;
  @include mq(sp) {
    width: 100%;
  }
}
.home_sercive--contents .txt p {
  opacity: 0;
  transform: translateY(rem(20));
  transition: all .6s ease-out;
  @include mq(sp) {
    opacity: 1;
  }
}
.home_sercive--contents .txt p.point {
  display: inline-flex;
  margin-bottom: 6vw !important;
  @include mq(sp) {
    flex-direction: row-reverse;
    align-items: flex-end;
  }
  .sub {
    margin-right: rem(8);
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    line-height: 1.1;
    letter-spacing: 0;
    font-size: rem(16);
    @include mq(sp) {
      margin-left: sprem(16);
      margin-right: 0;
      font-size: sprem(13);
      writing-mode: horizontal-tb;
      font-family: "CabinetGrotesk-Regular", sans-serif;
    }
  }
  .num {
    font-size: rem(160);
    line-height: rem(126);
    letter-spacing: 0;
    @include mq(sp) {
      font-size: sprem(80);
      line-height: 0.65;
    }
  }
}
.home_sercive--contents .ttl {
  margin-bottom: rem(32);
  font-size: rem(48);
  line-height: 1.16;
  opacity: 0;
  transform: translateY(rem(20));
  letter-spacing: 4.8px;
  word-break: keep-all;
  transition: all .6s ease-out;
  @include mq(sp) {
    opacity: 1;
    margin-bottom: sprem(32);
    font-size: sprem(24);
    word-break: normal;
    line-height: 1.5;
  }
}
.home_sercive--contents .catch {
  margin-bottom: rem(32);
  font-size: rem(26);
  letter-spacing: .26em;
  line-height: 2.15;
  opacity: 0;
  transform: translateY(rem(20));
  transition: all .6s ease-out;
  @include mq(sp) {
    opacity: 1;
    margin-bottom: sprem(8);
    font-size: sprem(20);
    line-height: 1.8;
  }
}
.home_sercive--contents .txt p:not(.btn) {
  margin-bottom: rem(40);
  font-size: rem(18);
  line-height: 2.2;
  letter-spacing: .05em;
  @include mq(sp) {
    margin-bottom: sprem(24);
    font-size: sprem(14);
    line-height: 1.8;
  }
}
.home_sercive--contents .img {
  padding-right: rem(110);
  transition: all .4s ease-out;
  opacity: 1;
  @include mq(sp) {
    position: relative;
    margin-top: sprem(50);
    padding-right: 0;
    margin-bottom: sprem(20);
    height: auto;
    text-align: center;
  }
  img {
    width: auto;
    max-width: none;
    height: 100%;
    max-height: rem(720);
    transform-origin: bottom right;
    @include mq(sp) {
      width: 78%;
      max-width: 78%;
      height: auto;
      max-height: none;
      transform-origin: center;
      transform: rotate(5deg);
    }
  }
}
.home_sercive--contents .bloc:nth-child(even) .img img {
  @include mq(sp) {
    transform: rotate(-5deg);
  }
}
.sercive_bloc_img1 {
  transform: rotate(2deg);
}
.sercive_bloc_img3,
.sercive_bloc_img5 {
  opacity: 0;
  transform: rotate(-12deg) translateX(rem(-260));
}
.sercive_bloc_img2,
.sercive_bloc_img4 {
  opacity: 0;
  transform: rotate(-12deg) translateX(rem(-260));
}

@include mq(sp, min) {
  .home_sercive--contents .bloc.current {
    .txt .point,
    .txt .ttl,
    .txt .catch,
    .txt p {
      opacity: 1;
      transform: translateY(0);
    }
    .img {
      transform: rotate(0deg);
      opacity: 1;
    }
    .home_sercive--progress {
      opacity: 1;
    }
  }
  // .home_sercive--contents .bloc.current + .bloc {
  //   .img {
  //     transform: rotate(-20deg) translate(rem(-130), rem(-100));
  //     opacity: .5;
  //   }
  // }
  // .home_sercive--contents .bloc:has(+ .current) {
  //   .img {
  //     transform: rotate(2deg);
  //     opacity: .7;
  //   }
  // }
}
.home_sercive--progress {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  @include mq(sp) {
    display: none;
  }
  .first {
    color: #fff;
  }
  .bar {
    width: 1px;
    height: rem(268);
    margin: rem(24) 0;
    background-color: #454e57;
  }
}

/* home_company
********************************************** */
.home_company {
  position: relative;
  padding-top: rem(100);
  @include mq(sp) {
    padding-top: sprem(188);
    padding-bottom: 0;
    overflow: hidden;
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    mix-blend-mode: screen;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../images/home/company_blur.png);
    @include mq(sp) {
      display: none;
    }
  }
  .inner {
    position: relative;
    z-index: 2;
  }
}
.home_company--ring {
  position: absolute;
  &.ring1 {
    position: absolute;
    top: -13%;
    left: 12%;
    width: rem(264);
    height: rem(270);
    @include mq(sp) {
      top: 2%;
      left: 0;
      width: rem(132);
      height: rem(132);
    }
    img {
      filter: blur(6px);
      transform: rotate(48.09deg);
    }
  }
  &.ring2 {
    top: -1%;
    left: 39%;
    width: rem(204);
    height: rem(210);
    @include mq(sp) {
      top: 10%;
      left: auto;
      right: 34%;
      width: sprem(100);
      height: sprem(100);
    }
    img {
      transform: rotate(48.09deg);
      filter: blur(4px);
    }
  }
  &.ring3 {
    top: 18%;
    left: 54%;
    width: rem(202);
    height: rem(208);
    @include mq(sp) {
      left: 72%;
      width: rem(102);
      height: rem(102);
    }
    img {
      transform: rotate(48.09deg);
      filter: blur(4px);
    }
  }
  &.ring4 {
    left: 28%;
    bottom: 9%;
    width: rem(212);
    height: rem(218);
    @include mq(sp) {
      left: 40%;
      bottom: 28%;
      width: sprem(115);
      height: sprem(115);
    }
    img {
      transform: rotate(48.09deg);
      filter: blur(4px);
    }
  }
  &.ring5 {
    left: 11%;
    bottom: -6%;
    width: rem(202);
    height: rem(208);
    z-index: 2;
    @include mq(sp) {
      left: 1%;
      bottom: 23%;
      width: sprem(108);
      height: sprem(108);
    }
    img {
      transform: rotate(48.09deg);
      filter: blur(4px);
    }
  }
}
.home_company--ttl {
  margin-bottom: rem(72);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
  .font-en-m {
    font-size: rem(80);
    font-weight: normal;
    color: #fff;
    @include mq(sp) {
      font-size: sprem(40);
    }
  }
  .jp {
    display: block;
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
}
.home_company .catch {
  margin-bottom: rem(32);
  font-size: rem(26);
  letter-spacing: .26em;
  line-height: 2.15;
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.home_company--img_wrap {
  overflow: hidden;
  @include mq(sp) {
    margin-bottom: sprem(220);
  }
}
.home_company--imgs {
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: rem(32);
  grid-row-gap: rem(32);
  margin-bottom: rem(60);
  @include mq(sp) {
    align-items: center;
    margin-bottom: sprem(24);
    grid-column-gap: sprem(16);
    grid-row-gap: sprem(16);
  }
  li {
    text-align: center;
  }
  li:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
    width: 30.73vw;
    height: 19.69vw;
  }
  li:nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
    width: 23.44vw;
    height: 15.63vw;
  }
  li:nth-child(3) {
    grid-area: 1 / 2 / 3 / 4;
    width: 46.04vw;
    height: 29.69vw;
    @include mq(sp) {
      width: 80.04vw;
      height: 51.69vw;
    }
  }
  li:nth-child(4) {
    grid-area: 1 / 4 / 2 / 5;
    width: 23.44vw;
    height: 19.69vw;
  }
  li:nth-child(5) {
    grid-area: 2 / 4 / 3 / 5;
    width: 30.73vw;
    height: 19.69vw;
  }
  li:nth-child(6) {
    grid-area: 1 / 5 / 3 / 7;
    width: 46.04vw;
    height: 29.69vw;
  }
}
.home_company .inner-sm {
  margin-right: 0;
  max-width: rem(895);
  @include mq(sp) {
    max-width: 100%;
    width: 100%;
  }
}
.home_company p:not(.btn) {
  margin-bottom: rem(64);
  font-size: rem(18);
  line-height: 2.2;
  @include mq(sp) {
    margin-bottom: sprem(24);
    font-size: sprem(14);
    line-height: 1.8;
  }
}

/* home_message
********************************************** */
.home_message {
  position: relative;
  padding-bottom: 0;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -80%;
    width: 100%;
    height: rem(3864);
    background-repeat: no-repeat;
    background-image: url(../images/home/message_light.png);
    mix-blend-mode: screen;
    background-size: cover;
    -webkit-mask-image: linear-gradient(90deg, #0000, #fff, #0000);
        mask-image: linear-gradient(90deg, #0000, #fff, #0000);
    -webkit-mask-size: 300% 100%;
        mask-size: 300% 100%;
    animation: clip-fade 3s infinite alternate;
    pointer-events: none;
    @include mq(sp) {
      display: none;
    }
  }
  .inner.flex {
    margin-right: 0;
    max-width: 94%;
    width: 94%;
    @include mq(sp) {
      margin-right: auto;
      width: 86%;
      max-width: 86%;
    }
  }
}
.home_message .txt {
  position: relative;
  z-index: 3;
  width: 51%;
  margin-right: -26%;
  @include mq(sp) {
    width: 100%;
    margin-right: 0;
  }
}
.home_message .img {
  width: 100%;
  text-align: right;
  @include mq(sp) {
    &.pc-none {
      display: flex;
      justify-content: flex-end;
      width: 115%;
      margin: 0 -7% sprem(32);
    }
    img {
      width: 110%;
      max-width: 110%;
    }
  }
}
.home_message .sub_ttl {
  margin-bottom: rem(24);
  font-size: rem(40);
  letter-spacing: 0;
  font-weight: normal;
  color: #92c8e1;
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.home_message .catch {
  margin-bottom: rem(32);
  font-size: rem(26);
  letter-spacing: .26em;
  line-height: 2.15;
  @include mq(sp) {
    font-size: sprem(19);
    line-height: 1.8;
  }
}
.home_message p:not(.btn) {
  margin-bottom: rem(45);
  font-size: rem(18);
  line-height: 2.2;
  @include mq(sp) {
    margin-bottom: sprem(24);
    font-size: sprem(14);
    line-height: 2;
  }
}
.home_message p.name {
  margin-bottom: rem(32);
  font-size: rem(26);
  letter-spacing: .26em;
  @include mq(sp) {
    font-size: sprem(16);
  }
  span {
    display: inline-block;
    margin-right: rem(16);
    font-size: rem(16);
    letter-spacing: .26em;
    @include mq(sp) {
      margin-right: sprem(8);
      font-size: sprem(14);
    }
  }
}
.home_message p.home_message--name {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  margin-top: rem(-100);
  font-size: rem(195);
  text-align: center;
  line-height: 1;
  color: #fff;
  @include mq(sp) {
    display: block;
    margin-top: sprem(-128);
    font-size: sprem(83);
    text-align: left;
    span:not(:last-child) {
      margin-right: sprem(10);
    }
  }
}
.home_message--ring {
  position: absolute;
  left: 39%;
  bottom: 5%;
  width: rem(374);
  height: rem(380);
  filter: blur(6px);
  @include mq(sp) {
    left: auto;
    right: 43%;
    bottom: 40%;
    width: sprem(176);
    height: sprem(176);
  }
}

/* home_media
********************************************** */
.home_media {
  position: relative;
  z-index: 3;
}
.home_media--wrap {
  @include mq(sp) {
    padding-bottom: 0;
  }
}
.home_media--info {
  position: sticky;
  top: rem(160);
  width: rem(320);
  height: 90vh;
  @include mq(sp) {
    position: static;
    width: 100%;
    height: auto;
  }
}
.home_media--ttl {
  margin-bottom: rem(80);
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
  .font-en-m {
    font-weight: normal;
    font-size: rem(80);
    letter-spacing: 0;
    color: #fff;
    @include mq(sp) {
      font-size: sprem(40);
    }
  }
  .jp {
    display: block;
    letter-spacing: .26em;
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
}
.home_media_scroll {
  flex: 1;
  margin-left: rem(208);
  @include mq(sp) {
    margin-left: 0;
  }
}
.home_media--contents {
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
  li {
    @include mq(sp) {
      align-items: flex-start;
    }
  }
  li:not(:last-child) {
    margin-bottom: rem(88);
    @include mq(sp) {
      margin-bottom: sprem(24);
    }
  }
}
.home_media--contents .txt {
  flex: 1;
  margin-left: rem(72);
  @include mq(sp) {
    margin-left: sprem(22);
    font-size: sprem(12);
  }
}
.home_media--contents .ttl {
  margin-bottom: rem(32);
  font-size: rem(26);
  @include mq(sp) {
    margin-bottom: sprem(8);
    font-size: sprem(16);
  }
}
.home_media--contents .font-en {
  margin-bottom: rem(16);
  font-size: rem(18);
  @include mq(sp) {
    margin-bottom: sprem(4);
    font-size: sprem(14);
  }
}
.home_media--contents .img {
  @include mq(sp) {
    width: 20%;
  }
}
.home_media--contents .img img {
  width: rem(274);
  box-shadow: rem(16) rem(16) rem(60) rgba(81,126,176,.38);
}

/* home_news
********************************************** */
.home_news {
  position: relative;
  z-index: 3;
}
.home_news--ring {
  position: absolute;
  @include mq(sp) {
    display: none;
  }
  &.ring1 {
    top: -70%;
    left: 0;
    width: rem(200);
    height: rem(228);
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      right: rem(10);
      bottom: 0;
      margin: auto 0;
      width: rem(212);
      max-width: none;
      height: rem(218);
      filter: blur(6px);
      transform: rotate(48.09deg);
    }
  }
  &.ring2 {
    top: -30%;
    left: 8%;
    width: rem(300);
    height: rem(306);
    img {
      transform: rotate(103.83deg);
    }
  }
}
.home_news--info {
  width: rem(320);
}
.home_news--ttl {
  margin-bottom: rem(80);
  font-weight: normal;
  letter-spacing: .26em;
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
  .font-en-m {
    font-size: rem(80);
    letter-spacing: 0;
    color: #fff;
    @include mq(sp) {
      font-size: sprem(40);
    }
  }
  .jp {
    display: block;
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
}
.home_news .posts-news {
  flex: 1;
  margin-left: rem(164);
  @include mq(sp) {
    margin-left: 0;
    margin-bottom: sprem(24);
  }
}
.posts-news--ttl {
  display: block;
  position: relative;
  margin-right: rem(40);
  padding: 0;
  line-height: 1.2;
  @include mq(sp) {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: sprem(16);
  }
}

/* home_lastmsg
********************************************** */
.home_lastmsg {
  position: relative;
  padding-top: rem(384);
  padding-bottom: rem(430);
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq(sp) {
    padding-top: sprem(200);
    padding-bottom: sprem(200);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    mix-blend-mode: screen;
    background-size: cover;
    transform: translate3d(0,0,0);
  }
  &::before {
    top: -25%;
    left: 0;
    width: rem(658);
    height: rem(1250);
    background-image: url(../images/common/lights-02.png);
    opacity: .8;
    -webkit-mask-image: linear-gradient(90deg, #0000, #fff, #0000);
        mask-image: linear-gradient(90deg, #0000, #fff, #0000);
    -webkit-mask-size: 300% 100%;
        mask-size: 300% 100%;
    animation: clip-fade 3s infinite alternate;
    @include mq(sp) {
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
  &::after {
    top: -19%;
    right: 0;
    width: rem(953);
    height: rem(2049);
    background-image: url(../images/common/lights-05.png);
    opacity: .6;
    -webkit-mask-image: linear-gradient(160deg, #0000, #fff, #0000);
        mask-image: linear-gradient(160deg, #0000, #fff, #0000);
    -webkit-mask-size: 100% 400%;
        mask-size: 100% 400%;
    animation: clip-fade 3s infinite alternate;
    @include mq(sp) {
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
}
.home_lastmsg--ring {
  position: absolute;
  inset: 0;
  width: rem(612);
  height: rem(618);
  margin: auto;
  @include mq(sp) {
    width: sprem(280);
    height: sprem(280);
  }
  img {
    transform: rotate(64.08deg);
  }
}
.home_lastmsg--ttl {
  position: relative;
  z-index: 1;
  text-align: center;
  .font-en {
    display: flex;
    justify-content: center;
    margin-bottom: rem(16);
    font-size: rem(195);
    line-height: 1;
    color: #fff;
    font-weight: normal;
    @include mq(sp) {
      font-size: 9.5vw;
    }
    span {
      display: inline-block;
      letter-spacing: -.01em;
    }
  }
  .jp {
    display: inline-block;
    font-size: rem(40);
    letter-spacing: .26em;
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
}