@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  z-index: 999;
  transition: var(--transit-default);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: rem(44);
    padding-right: rem(48);
  }
  &.is-hidden {
    translate: 0 -100%;
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    & > .inner {
      padding-left: rem(30);
    }
  }
}
.template-contact-simple .header {
  position: absolute;
}
.header-sm {
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
    background-color: rgba(243, 244, 239, .3);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    .header--logo a .bk {
      opacity: 1;
    }
  }
  @include mq(med) {
    background-color: rgba(243, 244, 239, .3);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    .header--logo a .bk {
      opacity: 1;
    }
  }
}
.header.active {
  @include mq(med) {
    .header--logo a .bk {
      opacity: 1;
    }
  }
}

/* --- logo -- */
.header--logo {
  width: rem(598);
  @include mq(sp) {
    position: relative;
    z-index: 999;
    width: auto;
  }
}
.header--logo a,
.footer--logo a {
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}
.header--logo a {
  position: relative;
  .bk {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all .4s ease-out;
  }
}
.header--logo img {
  @include mq(med) {
    width: auto !important;
    max-height: rem(42);
  }
}

/* gnav
********************************************** */
.gnav,
.gnav--menu,
.gnav--menu li,
.gnav--link {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 300;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: sprem(80) 7% rem(24);
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    transition: all .6s ease-out;
    background-image: url(../images/common/body_bg.png);
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
  }
}
.gnav--link {
  padding: 0 rem(24);
  font-size: rem(18);
  letter-spacing: .1em;
  font-family: var(--font-primary);
  @include mq(med) {
    display: flex;
    align-items: center;
    padding: sprem(8) 0;
  }
  @include mq(med, min, ps) {
    text-align: center;
    @include center-flex;
  }
  & > span {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  .bf {
    display: inline-block;
    transform: translateZ(0);
    transform-origin: 50% 0;
    transition: all .4s ease-out;
  }
  .hvr {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 100%, 0);
    transform-origin: 50% 100%;
    transition-delay: 0s;
    transition: all .4s ease-out;
  }
  &:hover .bf {
    transform: translate3d(0, -110%, 0);
    transition-delay: 0s;
  }
  &:hover .hvr {
    transform: translateZ(0);
    transition-delay: .04s;
  }
}
.gnav--menu > li:not(.menu-item-has-children) > a::after {
  @include mq(med) {
    content: "";
    inline-size: sprem(13);
    margin-block: calc((1lh - sprem(7)) / 2);
    margin-left: auto;
    margin-right: sprem(16);
    aspect-ratio: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23454e57' viewBox='0 0 13 12'%3E%3Cpath d='M6.762,0.387 L5.564,1.649 L8.881,5.140 L0.753,5.140 L0.753,6.925 L8.881,6.925 L5.564,10.412 L6.762,11.674 L12.126,6.033 L6.762,0.387 Z'/%3E%3C/svg%3E");
  }
}
.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    color: var(--clr-primary-default);
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  z-index: 100;
  @include mq(med, min, ps) {
    position: absolute;
    top: 76%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    transition: var(--transit-default);
    opacity: 0;
    pointer-events: none;
    padding: rem(46) rem(54);
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
  }
  @include mq(med) {
    display: block;
    padding-left: sprem(25);
  }
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
    margin-bottom: sprem(16);
  }
}
.head_sub_nav li {
  &:not(:last-child) {
    margin-bottom: rem(16);
  }
}
.head_sub_nav a {
  position: relative;
  justify-content: flex-start;
  padding-left: rem(24);
  font-size: rem(16);
  @include mq(med) {
    padding: sprem(8) 0 0;
    padding-right: sprem(38);
    line-height: 1.2;
  }
  &:hover {
    @include mq(med, min, ps) {
      opacity: .7;
    }
  }
  &:not([href="javascript:void(0);"])::before {
    content: "";
    position: absolute;
    top: rem(8);
    left: 0;
    display: inline-block;
    @include sprect(13);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23454e57' viewBox='0 0 13 12'%3E%3Cpath d='M6.762,0.387 L5.564,1.649 L8.881,5.140 L0.753,5.140 L0.753,6.925 L8.881,6.925 L5.564,10.412 L6.762,11.674 L12.126,6.033 L6.762,0.387 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include mq(sp) {
      left: auto;
      right: sprem(14);
      inline-size: sprem(10);
    }
  }
}
.head_sub_nav a span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: 0 0;
  transition: all .4s ease-out;
}
.head_sub_nav a:hover span::after {
  background: var(--clr-text-primary);
  animation: btn-line .5s ease-out 0s 1 normal forwards;
}
@keyframes btn-line {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform-origin: right top;
    transform: scale(0, 1);
  }
  51% {
    transform-origin: left top;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: left top;
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    display: none;
    position: absolute;
    top: 0;
    right: rem(-12);
    @include sprect(56);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(14);
      height: 1px;
      border-bottom: 1px solid;
      transition: var(--transit-default);
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active::after {
      transform: rotate(0deg);
    }
  }
}

/* ---btn --- */
.gnav_btn,
.tel_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: sprem(10);
    @include sprect(46);
    cursor: pointer;
    z-index: 9999;
  }
}
.gnav_btn {
  @include mq(med) {
    right: sprem(15);
    border-radius: 50%;
    background-color: #fff;
    transition: var(--transit-default);
    &.active {
      background-color: var(--clr-bg-02);
    }
  }
}
.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(19, 8);
  }
}
.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #454e57;
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      width: sprem(12);
      bottom: 0;
    }
  }
}
.active .gnav_btn--lines span {
  @include mq(med) {
    &:nth-of-type(1) {
      transform: translateY(sprem(2)) rotate(-45deg);
    }
    &:nth-of-type(2) {
      width: 100%;
      transform: translateY(sprem(-4)) rotate(45deg);
    }
  }
}
.tel_btn {
  @include mq(med) {
    right: sprem(64);
  }
  a {
    @include mq(med) {
      display: flex !important;
      width: 100%;
      height: 100%;
    }
  }
  svg {
    @include mq(med) {
      @include sprect(24);
      fill: var(--clr-primary-default);
    }
  }
}
.gnav--cta {
  margin-left: rem(24);
  text-align: center;
  @include mq(sp) {
    margin-top: sprem(24);
    margin-left: 0;
  }
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.gnav--tel,
.gnav--contact {
  @include mq(med, min, ps) {
    margin-left: rem(8);
  }
}
.gnav--tel a {
  display: inline-flex !important;
  flex-direction: column;
  margin-inline: auto;
}
.gnav--tel .tel {
  font-size: calc-fz(20);
}
.gnav--tel .num {
  font-size: calc-fz(24);
}
.gnav--tel .hours {
  font-size: calc-fz(14);
}
.gnav--contact {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gnav--contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0,192,250);
  background: linear-gradient(135deg, rgba(0,192,250,1) 0%, rgba(1,94,234,1) 100%);
  box-shadow: 0 0 12px rgba(56,146,213,.3);
  width: rem(190);
  height: rem(78) !important;
  font-size: rem(18);
  letter-spacing: .1em;
  @include mq(med) {
    padding: sprem(10) sprem(16);
    padding-left: sprem(32);
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-start;
  }
  &::before {
    display: none;
  }
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    transition: all .4s ease-out;
    background-color: rgba(255,255,255,0);
    border-radius: 99px;
  }
  &:hover::after {
    background-color: rgba(255,255,255,.35);
  }
}
.gnav--contact .circle {
  position: absolute;
  top: 0;
  right: rem(28);
  bottom: 0;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include rect(18);
  border-radius: 50%;
  background-color: rgba($color: #fff, $alpha: .2);
  &::before {
    content: "";
    @include rect(6);
    border-radius: 50%;
    background-color: #fff;
  }
}