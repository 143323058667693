@use '../abstracts' as *;

/* ##############################################################################

  FORM

############################################################################## */
form * {
  font-family: var(--font-default) !important;
}
nf-field {
  display: block;
  &:not(:first-child) {
    padding-top: 1.5em;
    border-top: 1px solid #ddd;
  }
  &:not(:last-child) {
    padding-bottom: 1.5em;
  }
  &:has(input[type="submit"]) {
    padding-top: 0;
    border-top: 0;
  }
}
.nf-form-cont .nf-form-content label,
.nf-form-cont .nf-field-label .nf-label-span {
  font-size: calc-fz(18);
  @include mq(sp) {
    font-size: sprem(15);
    margin-bottom: 6px!important;
  }
}
.nf-form-cont .nf-form-fields-required {
  display: none !important;
}
#ninja_forms_required_items,
.nf-form-cont .nf-field-container {
  margin-bottom: 0;
}
.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element>div,
.nf-form-cont .nf-form-content input:not([type=button]),
.nf-form-cont .nf-form-content textarea {
  background: var(--clr-wht);
  color: var(--clr-text-primary);
  border-radius: rem(3);
  vertical-align: bottom;
  font-size: calc-fz(16);
  transition: var(--transit-default, all .4s ease-out);
  font-weight: 500;
}
.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element>div:focus,
.nf-form-cont .nf-form-content input:not([type=button]):focus,
.nf-form-cont .nf-form-content textarea:focus {
  color: var(--clr-text-primary);
}
.nf-form-content .list-checkbox-wrap .nf-field-element li label,
.nf-form-content .list-image-wrap .nf-field-element li label,
.nf-form-content .list-radio-wrap .nf-field-element li label {
  cursor: pointer;
}
.nf-form-content input[type=checkbox]:focus+label:after,
.nf-form-content input[type=radio]:focus+label:after {
  box-shadow: none;
}
.checkbox-wrap .nf-field-element label.nf-checked-label:before,
.checkbox-wrap .nf-field-label label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-element label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-label label.nf-checked-label:before {
  color: var(--clr-primary-default);
}
.nf-form-cont .ninja-forms-req-symbol {
  font-size: 0 !important;
  &::before {
    content: "\5FC5\9808"; /* 必須 */
    background-color: #f55;
    color: var(--clr-wht);
    margin-left: .5em;
    font-size: calc-fz(12);
    padding: rem(2) rem(5);
    border-radius: rem(3);
  }
}
.nf-form-cont .nf-form-content .submit-wrap {
  text-align: center;
}
.nf-form-cont .nf-form-content .submit-wrap input:not([type=button]) {
  min-width: min(rem(310), 100%);
  height: auto;
  padding: 1.25em;
  font-size: calc-fz(18);
  line-height: 1;
  letter-spacing: .1em;
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  border-radius: rem(50);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  transition: var(--transit-default, all .4s ease-out);
  @include mq(sp, min) {
    margin-top: sprem(24);
  }
}
.nf-form-cont .nf-form-errors {
  margin-top: -1em;
  text-align: center;
}

@include mq(sp, min, ps) {
}
@include mq(sp) {
}

/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge { visibility: hidden; }
.recaptcha_policy {
  padding: 0;
  margin-top: rem(48);
  color: var(--clr-text-primary);
  font-size: calc-fz(10);
  text-align: center;
  letter-spacing: 0;
  @include mq(sp) {
    text-align: left;
  }
}
.recaptcha_policy a {
  color: #69ABDB;
  font-size: calc-fz(12);
  text-decoration: underline;
}