@use "../abstracts" as *;

.footer_cta {
  position: relative;
  z-index: 3;
  height: rem(960);
  background-color: rgba(#6bb7e0, .5);
  background-size: cover;
  background-position: bottom center;
  background-image: url(../images/common/contents-bg.jpg);
  background-attachment: fixed;
  color: #fff;
  @include mq(sp) {
    height: sprem(380);
    overflow: hidden;
  }
  .footer_cta--link {
    position: absolute;
    inset: 0;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0);
    mix-blend-mode: color-burn;
    @include mq(sp) {
      background-color: rgba(0, 0, 0, .15);
    }
    &:hover {
      background-color: rgba(0, 0, 0, .15);
    }
  }
  .inner {
    position: relative;
    z-index: 3;
    pointer-events: none;
  }
}
.footer_cta--imgs {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: space-around;
  @include mq(sp) {
    justify-content: space-between;
  }
  .photo1 {
    width: rem(681);
    height: 100%;
    background-size: rem(681) rem(1427);
    background-image: url(../images/common/cta-slide-01.png);
    animation: bgscroll_horizon_lft 80s linear infinite;
    @include mq(sp) {
      background-size: 100% auto;
      margin-left: -15%;
      margin-right: 15%;
      background-image: url(../images/common/cta-slide-01_sp.png);
    }
  }
  .photo2 {
    width: rem(660);
    height: 100%;
    background-size: rem(660) rem(1688);
    background-image: url(../images/common/cta-slide-02.png);
    animation: bgscroll_horizon_ctr 80s linear infinite;
    @include mq(sp) {
      display: none;
      background-size: 100% auto;
    }
  }
  .photo3 {
    width: rem(619);
    height: 100%;
    background-size: rem(619) rem(2350);
    background-image: url(../images/common/cta-slide-03.png);
    animation: bgscroll_horizon_rgt 80s linear infinite;
    @include mq(sp) {
      background-image: url(../images/common/cta-slide-02_sp.png);
      background-size: 100% auto;
      margin-right: -15%;
    }
  }
}
@keyframes bgscroll_horizon_lft {
  0% { background-position: 0 0; }
  100% { background-position: 0 rem(1427);}
}
@keyframes bgscroll_horizon_ctr {
  0% { background-position: 0 rem(1688); }
  100% { background-position: 0 0; }
}
@keyframes bgscroll_horizon_rgt {
  0% { background-position: 0 0; }
  100% { background-position: 0 rem(2350); }
}
.footer_cta--ttl {
  margin-bottom: rem(40);
  font-weight: normal;
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
  a {
    position: relative;
    display: inline-block;
    color: #fff;
    @include mq(sp) {
      padding-right: sprem(72);
    }
  }
}
.footer_cta--ttl .font-en-m {
  display: block;
  font-size: rem(80);
  letter-spacing: 0;
  line-height: 1;
  @include mq(sp) {
    margin-bottom: sprem(8);
    font-size: sprem(40);
  }
}
.footer_cta--ttl .icon {
  position: absolute;
  top: 0;
  right: rem(-124);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include rect(95);
  border-radius: 50%;
  border: 1px solid;
  transition: all .4s ease-out;
  @include mq(sp) {
    top: sprem(7);
    right: 0;
    @include sprect(56);
    background-color: #fff;
  }
  svg {
    @include rect(17);
    transform: rotate(-45deg);
    fill: #fff;
    transition: all .4s ease-out;
    @include mq(sp) {
      fill: var(--clr-primary-default);
    }
  }
}
.footer_cta:hover .footer_cta--ttl .icon {
  background-color: #fff;
  svg {
    fill: var(--clr-primary-default);
    animation: btn_arrow .4s forwards;
  }
}
@keyframes btn_arrow {
  0%, 100% {
    translate: 0 0;
    opacity: 1;
  }
  30% {
    translate: 50% -50%;
    opacity: 0;
  }
  60% {
    translate: -50% 50%;
    opacity: 0;
  }
}


/* ##############################################################################

    FOOTER

############################################################################## */
.footer {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: bottom center;
  background-image: url(../images/common/contents-bg.jpg);
  background-attachment: fixed;
  @include mq(sp) {
    background-image: url(../images/common/contents-bg-sp.jpg);
  }
}

/* footer_top
********************************************** */
.footer_top {
  position: relative;
  z-index: 3;
  @include mq(sp) {
    padding-bottom: sprem(32);
  }
  & > .inner {
    @include mq(sp) {
      flex-direction: column-reverse;
    }
  }
}
.footer_top--info {
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}

/* ---fnav --- */
.fnav--menu {
  column-count: 2;
  @include mq(sp) {
    column-count: 1;
  }
}
.fnav--menu li {
  margin-right: rem(32);
  margin-bottom: rem(24);
  @include mq(sp) {
    margin-right: 0;
    margin-bottom: sprem(8);
  }
}
.fnav--menu a {
  position: relative;
  display: inline-block;
  padding-left: rem(24);
  font-size: rem(18);
  overflow: hidden;
  @include mq(sp) {
    font-size: sprem(14);
  }
  .bf {
    display: inline-block;
    transform: translateZ(0);
    transform-origin: 50% 0;
    transition: all .4s ease-out;
  }
  .hvr {
    position: absolute;
    top: 0;
    left: rem(24);
    transform: translate3d(0, 100%, 0);
    transform-origin: 50% 100%;
    transition-delay: 0s;
    transition: all .4s ease-out;
  }
  &:hover .bf {
    transform: translate3d(0, -110%, 0);
    transition-delay: 0s;
  }
  &:hover .hvr {
    transform: translateZ(0);
    transition-delay: .04s;
  }
  @include mq(sp) {
    position: relative;
  }
  &[href="javascript:void(0);"] {
    padding-left: 0;
  }
  &:not([href="javascript:void(0);"])::before {
    content: "";
    position: absolute;
    top: rem(8);
    left: 0;
    display: inline-block;
    @include sprect(13);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23454e57' viewBox='0 0 13 12'%3E%3Cpath d='M6.762,0.387 L5.564,1.649 L8.881,5.140 L0.753,5.140 L0.753,6.925 L8.881,6.925 L5.564,10.412 L6.762,11.674 L12.126,6.033 L6.762,0.387 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.fnav--menu .sub-menu {
  margin-top: rem(24);
  margin-left: rem(24);
  @include mq(sp) {
    margin-top: 0;
  }
}

/* ---sns_area --- */
.sns_area--ttl {
  margin-right: rem(8);
  font-weight: normal;
}
.sns_area a {
  display: grid;
  place-items: center;
  @include rect(40);
  &:hover {
    opacity: 0.7;
  }
}
.sns_area svg {
  @include rect(24);
  fill: #454e57;
  &.icon-x {
    @include rect(22);
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  position: relative;
  z-index: 3;
  .inner {
    padding-top: rem(64);
    padding-bottom: rem(64);
    border-top: 1px solid #99b6bf;
  }
}
.fnav_sub {
  margin-left: auto;
  margin-right: rem(24);
  @include mq(sp) {
    margin-right: auto;
  }
}
.fnav_sub ul {
  @include mq(sp) {
    justify-content: center;
  }
}
.fnav_sub ul li {
  margin-inline: rem(16);
  line-height: 1;
  @include mq(sp) {
    margin-inline: 0;
    margin-right: 16px;
    margin-bottom: 8px;
  }
}
.fnav_sub a {
  position: relative;
  display: inline-block;
  overflow: hidden;
  line-height: 1.2;
  span {
    line-height: 1.2;
  }
  .bf {
    display: inline-block;
    transform: translateZ(0);
    transform-origin: 50% 0;
    transition: all .4s ease-out;
  }
  .hvr {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 100%, 0);
    transform-origin: 50% 100%;
    transition-delay: 0s;
    transition: all .4s ease-out;
  }
  &:hover .bf {
    transform: translate3d(0, -110%, 0);
    transition-delay: 0s;
  }
  &:hover .hvr {
    transform: translateZ(0);
    transition-delay: .04s;
  }
}
.page_top {
  @include mq(sp) {
    margin-top: sprem(24);
    margin-bottom: sprem(24);
    text-align: right;
  }
}
.page_top a {
  display: inline-flex;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.page_top .arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include rect(56);
  border-radius: 50%;
  border: 1px solid var(--clr-text-secondary);
  margin-left: rem(16);
  svg {
    @include rect(13);
    transform: rotate(-90deg);
  }
}
.copyright {
  gap: 1em;
  justify-content: center;
  @include mq(sp) {
    gap: 0.5em;
    order: 2;
    text-align: center;
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}