@use "sass:math";
@use '../abstracts' as *;

// 単位は㎳（ミリ秒）
// $aniMax: 4000; // 最大遅延時間
// $aniStep: 100; // 遅延のステップ

// // 1から12までのアニメーション遅延を設定
// 単位は㎳（ミリ秒）
$aniMax: 4000; // 最大
$aniStep: 100; // ステップ
@for $i from 0 through ceil(math.div($aniMax, $aniStep)) {
  $val: $i * $aniStep;
  .ani-delay#{$val} {
    animation-delay: #{$val}ms;
    transition-delay: #{$val}ms !important;
  }
}

// 下から上にフェードイン
@mixin neutral_base {
	transform: translateY(rem(20));
  opacity: 0;
  transition: all .6s ease;

	&.loaded,
  &.active {
		transform: translateY(0);
    opacity: 1;
	}
}

.load_active,
.scroll_active {
  @include neutral_base;
}

.fadein {
  animation: fadein 1s forwards;
}
@keyframes fadein {
  0% {
    transform: translateY(rem(20));
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.fuwafuwa-x {
  display: inline-block;
  animation: fuwafuwa-x 15s ease-in-out infinite alternate-reverse;
}
.fuwafuwa-y {
  display: inline-block;
  animation: fuwafuwa-y 10s ease-in-out infinite alternate-reverse;
}
@keyframes fuwafuwa-x {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5%);
  }
  75% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fuwafuwa-y {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10%);
  }
  75% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes clip-fade {
  100% {
    -webkit-mask-position: right;
            mask-position: right;
  }
}

@keyframes text-blur {
  0% {
    background-position: top left -200%;
  }
  50% {
    background-position: top left 300%;
  }
  100% {
    background-position: top left -200%;
  }
}

.shine {
  animation: shine 2s forwards;
}
@keyframes shine {
  0% {
    background-position: 530% 50%;
  }
  100% {
    background-position: 340% 50%;
  }
}