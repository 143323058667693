@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
/* --- 共通 --- */
.lps_parts--faq {
  max-width: rem(1256);
  margin-right: auto;
  @include mq(sp) {
    width: 86%;
    margin-left: auto;
  }
  .ttl {
    margin-bottom: 0 !important;
  }
  .text {
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32,768,'%');
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300,768,'%');
    }
  }
  .mce-content-body {
    @include mq(sp, min, ps) {
      margin-bottom: 0 !important;
      flex: 1;
    }
  }
}

/* --- 通常 --- */
.lps_parts--faq:not(.faq-toggle) {
  .faq_item {
    border: 2px solid var(--clr-primary-default);
    background-color: var(--clr-wht);
    &:not(:last-child) {
      margin-bottom: rem(40);
      @include mq(sp) {
        margin-bottom: sprem(16);
      }
    }
  }
  .ttl,
  .text {
    padding: rem(28) rem(32);
    @include mq(sp) {
      padding: sprem(24);
    }
  }
  .ttl {
    background-color: var(--clr-primary-default);
    color: var(--clr-wht);
  }
}

/* --- 開閉式 --- */
.lps_parts--faq.faq-toggle {
  .faq_item {
    border-top: 1px solid var(--clr-text-secondary);
    border-bottom: 1px solid var(--clr-text-secondary);
    padding-block: rem(60);
    padding-right: rem(46);
    @include mq(sp) {
      padding: sprem(24) 0;
    }
    &:not(:first-child) {
      border-top: 0;
    }
    &:hover .ttl {
      padding-left: rem(24);
    }
  }
  .ttl {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: rem(56);
    transition: all .4s ease-out;
    @include mq(sp) {
      flex-wrap: wrap;
    }
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
    &::before {
      content: '';
      position: absolute;
      inset: rem(-40) rem(-30);
      @include mq(sp) {
        inset: sprem(-24);
      }
    }
  }
  .ttl .font-en {
    display: flex;
    align-items: center;
    margin-right: rem(24);
    margin-bottom: 0;
    padding: rem(8) rem(24) rem(4) rem(20);
    border-radius: 99px;
    background-color: var(--clr-primary-default);
    font-size: rem(24);
    font-weight: normal;
    color: #fff;
    @include mq(sp) {
      margin-right: 0;
      margin-bottom: 12px;
      padding: sprem(4) rem(16) sprem(4) rem(8);
      font-size: sprem(16);
    }
    &::before {
      content: "";
      @include rect(8);
      border-radius: 50%;
      background-color: #b4d3de;
      margin-bottom: rem(3);
      margin-right: rem(8);
    }
  }
  .ttl .font-en + span {
    flex: 1;
    @include mq(sp) {
      flex: auto;
      width: 100%;
    }
  }
  .ttl .toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    @include rect(48);
    transition: var(--transit-default);
    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      width: rem(21);
      height: 2px;
      border-bottom: 2px solid #314f84;
      transition: var(--transit-default);
    }
    &::after {
      transform: rotate(90deg);
    }
  }
  .ttl:hover .toggle {
    opacity: .5;
  }
  .ttl.active .toggle {
    transform: rotate(180deg);
    &::after {
      opacity: 0;
    }
  }
  .text {
    position: relative;
    padding-top: rem(40);
    padding-left: rem(72);
    @include mq(sp) {
      padding-top: sprem(20);
      padding-left: rem(32);
    }
    &::before {
      content: "";
      position: absolute;
      top: rem(51);
      left: rem(32);
      width: rem(21);
      border-bottom: 3px solid var(--clr-primary-default);
      @include mq(sp) {
        top: sprem(30);
        left: 0;
        width: sprem(16);
      }
    }
  }
}